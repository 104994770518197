import { authPostDataRequest } from './auth-post-data-request';
import { config } from './config';
import { ApiResponse, fetchHandler } from './fetchHandler';

export const webOrdersService = {
  getCurrent,
  getNameSearchResult,
  getById,
  getOrderItems,
  getInvoices,
  getWebOrderStatuses,
  setStatus,
  getUserActions,
  getPaymentMethods,
  getShippingMethods,
  getInvoice,

  createPaymentMethod,
  createShippingMethod,
  createInvoice,
  addInvoiceItem,
  addFreightTicket,
  addProduct,
  createOrder,
  addUserAddress,
  resendInvoice,

  savePickings,
  setPaid,
  setUnpaid,
  setOrderDetails,
  setPaymentMethod,
  setShippingMethod,
  updateOrderItem,
  setPickupDate,

  deleteOrderItem,
  voidInvoice,
  voidInvoices,
};

type UserActionResponse = {
  time: number;
  userId: number;
  userName: string;
  message: string;
}

type GetCurrentResponse = {
  webOrderId: number;
  time: number;
  customerId: number;
  customerFirstName: string;
  customerLastName: string;
  paymentMethodId: number;
  paymentMethod: string;
  shippingMethodId: number;
  shippingMethod: string;
  orderStatusId: number;
  status: string;
  paymentReceivedTime: number;
  orderedItemCount: number;
  suppliedItemCount: number;
  containers: number;
  isPickup: boolean;
  isFreight: boolean;
  pickupTime: number;
  isPickupToday: boolean;
}

type GetByIdResponse = {
  time: number;
  customerId: number;
  customerFirstName: string;
  customerLastName: string;
  customerEmailAddress: string;
  paymentMethodId: number;
  paymentMethod: string;
  shippingMethodId: number;
  shippingMethod: string;
  isFreightOption: boolean;
  isDeliveryOption: boolean;
  isPickupOption: boolean;
  orderStatusId: number;
  status: string;
  paymentReceivedTime: number;
  phoneNumbers: PhoneNumber[] | null;
  freightTickets: any[] | null;
  address: Address | null;
  orderNotes: string;
  pickupDate: number;
  pickupTimeSlot: string;
  invoiceCount: number;
  creditTotal: number;
  pickupDateLocal: number
}

type OrderItemResponse = {
  webOrderItemId: number;
  productId: number;
  product: string;
  unitWeight: number;
  quantityOrdered: number;
  quantitySupplied: number;
  unitPrice: number;
  containerNumbers: string;
  containers: number[];
}

type GetStatusesResponse = {
  id: number;
  name: string;
  userPrompt: string;
}

type PhoneNumber = {
  type: string;
  number: string;
}

type Address = {
  street: string;
  suburb: string;
  city: string;
  postCode: string;
}

type InvoiceItem = {
  webOrderInvoiceId: number;
  time: number;
  notes: string;
  orderTotal: number;
  freightTotal: number;
  creditTotal: number;
  invoiceTotal: number;
  isVoid: boolean;
};

type PaymentMethodResponse = {
  id: number;
  name: string;
  webOrderInstructions: string;
  invoiceInstructions: string;
  isActive: boolean;
}

export type ShippingMethodResponse = {
  id: number;
  name: string;
  webOrderInstructions: string;
  invoiceInstructions: string;
  description: string;
  isActive: boolean;
  isDeliveryOption: boolean;
  isFreightOption: boolean;
  isPickupOption: boolean;
  sendToTrelloListId: string;
}

type InvoiceDetailResponse = {
  webOrderId: number;
  fullMessageBody: string;
  succeeded: boolean;
  recipientEmailAddress: string;
  lastAttemptTime: number;
}

function getCurrent(): Promise<GetCurrentResponse[]> {
  return fetchHandler(`${config.apiUrl}/weborders/get`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getNameSearchResult(name: string | null): Promise<GetCurrentResponse[]> {
  return fetchHandler(`${config.apiUrl}/weborders/get`, authPostDataRequest({ name }))
    .then((results) => { return results; });
}

function getById(webOrderId: number): Promise<GetByIdResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/details`, authPostDataRequest({ webOrderId }))
    .then((result) => { return result; });
}

function getInvoices(webOrderId: number): Promise<InvoiceItem[]> {
  return fetchHandler(`${config.apiUrl}/weborders/getInvoices`, authPostDataRequest({ webOrderId }))
    .then((result) => { return result; });
}

function getInvoice(webOrderInvoiceId: number): Promise<InvoiceDetailResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/getInvoiceDetails`, authPostDataRequest({ webOrderInvoiceId }))
    .then((result) => { return result; });
}

function getOrderItems(webOrderId: number): Promise<OrderItemResponse[]> {
  return fetchHandler(`${config.apiUrl}/weborders/getOrderItems`, authPostDataRequest({ webOrderId }))
    .then((results) => { return results; });
}

function getWebOrderStatuses(): Promise<GetStatusesResponse[]> {
  return fetchHandler(`${config.apiUrl}/weborders/getStatuses`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getUserActions(webOrderId: number): Promise<UserActionResponse[]> {
  return fetchHandler(`${config.apiUrl}/userAction/getForWebOrder`, authPostDataRequest({ webOrderId }))
    .then((results) => { return results; });
}

function getPaymentMethods(): Promise<PaymentMethodResponse[]> {
  return fetchHandler(`${config.apiUrl}/paymentMethod/get`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function getShippingMethods(): Promise<ShippingMethodResponse[]> {
  return fetchHandler(`${config.apiUrl}/shippingMethod/get`, authPostDataRequest({}))
    .then((results) => { return results; });
}

function savePickings(userPin: string, webOrderId: number, webOrderItemId: number, productId: number, locationId: number, quantityPicked: number, batchNumber: string, containerNumbers: string): Promise<{ success: boolean, message: string }> {
  return fetchHandler(`${config.apiUrl}/weborders/savePickings`, authPostDataRequest({ userPin, webOrderId, webOrderItemId, productId, locationId, quantityPicked, batchNumber, containerNumbers }))
    .then(result => { return result; });
}

function setPaid(userPin: string, webOrderId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/setPayment`, authPostDataRequest({ userPin, webOrderId }))
    .then(result => { return result; });
}

function setUnpaid(userPin: string, webOrderId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/revokePayment`, authPostDataRequest({ userPin, webOrderId }))
    .then(result => { return result; });
}

function setStatus(userPin: string, webOrderId: number, orderStatusId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/setStatus`, authPostDataRequest({ userPin, webOrderId, orderStatusId }))
    .then(result => { return result; });
}

function setPickupDate(userPin: string, webOrderId: number, pickupDate: string, emailCustomer: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/setPickupDate`, authPostDataRequest({ userPin, webOrderId, pickupDate, emailCustomer }))
    .then(result => { return result; });
}

function setOrderDetails(userPin: string, webOrderId: number, paymentMethodId: number, shippingMethodId: number, deleteFreightTickets: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/setOrderDetails`, authPostDataRequest({ userPin, webOrderId, paymentMethodId, shippingMethodId, deleteFreightTickets }))
    .then(result => { return result; });
}

function updateOrderItem(userPin: string, webOrderId: number, webOrderItemId: number, quantity: number, containerNumbers: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/setOrderItem`, authPostDataRequest({ userPin, webOrderId, webOrderItemId, quantity, containerNumbers }))
    .then(result => { return result; });
}

function deleteOrderItem(userPin: string, webOrderId: number, webOrderItemId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/deleteOrderItem`, authPostDataRequest({ userPin, webOrderId, webOrderItemId }))
    .then(result => { return result; });
}

function addFreightTicket(userPin: string, webOrderId: number, freightProviderId: number, ticketNumber: string, excessTickets: string, weight: number, charge: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/addFreightTicket`, authPostDataRequest({ userPin, webOrderId, freightProviderId, ticketNumber, excessTickets, weight, charge }))
    .then(result => { return result; });
}

function addProduct(userPin: string, webOrderId: number, productId: number, quantity: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/addProduct`, authPostDataRequest({ userPin, webOrderId, productId, quantity }))
    .then(result => { return result; });
}

function createInvoice(userPin: string, webOrderId: number, invoiceNotes: string, creditTotal: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/createInvoice`, authPostDataRequest({ userPin, webOrderId, invoiceNotes, creditTotal }))
    .then(result => { return result; });
}

function resendInvoice(webOrderId: number, webOrderInvoiceId: number, userPin: string, recipientEmailAddress: string): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/resendInvoice`, authPostDataRequest({ userPin, webOrderId, webOrderInvoiceId, recipientEmailAddress }))
    .then(result => { return result; });
}

function addInvoiceItem(webOrderId: number, quantity: number, invoiceItemName: string, unitPrice: number, productId?: number,): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/addInvoiceItem`, authPostDataRequest({ webOrderId, quantity, invoiceItemName, productId, unitPrice }))
    .then(result => { return result; });
}

function voidInvoice(webOrderId: number, userPin: string, sendEmail: boolean, webOrderInvoiceId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/voidInvoices`, authPostDataRequest({ webOrderId, userPin, sendEmail, webOrderInvoiceId }))
    .then(result => { return result; });
}

function voidInvoices(webOrderId: number, userPin: string, sendEmail: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/voidInvoices`, authPostDataRequest({ webOrderId, userPin, sendEmail }))
    .then(result => { return result; });
}

function setPaymentMethod(userPin: string, name: string, webOrderInstructions: string, invoiceInstructions: string, isActive: boolean, paymentMethodId: number): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/paymentMethod/set`, authPostDataRequest({ userPin, name, webOrderInstructions, invoiceInstructions, isActive, paymentMethodId }))
    .then(result => { return result; });
}

function createPaymentMethod(userPin: string, name: string, webOrderInstructions: string, invoiceInstructions: string, isActive: boolean): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/paymentMethod/set`, authPostDataRequest({ userPin, name, webOrderInstructions, invoiceInstructions, isActive }))
    .then(result => { return result; });
}

function setShippingMethod(userPin: string, name: string, webOrderInstructions: string, invoiceInstructions: string, description: string, sendToTrelloListId: string, isActive: boolean, isDeliveryOption: boolean, isFreightOption: boolean, isPickupOption: boolean, shippingMethodId: number): Promise<Response> {
  return fetchHandler(`${config.apiUrl}/shippingMethod/set`, authPostDataRequest({ userPin, name, webOrderInstructions, invoiceInstructions, description, sendToTrelloListId, isActive, isDeliveryOption, isFreightOption, isPickupOption, shippingMethodId }))
    .then(result => { return result; });
}

function createShippingMethod(userPin: string, name: string, webOrderInstructions: string, invoiceInstructions: string, isActive: boolean, isDeliveryOption: boolean, isFreightOption: boolean, isPickupOption: boolean): Promise<Response> {
  return fetchHandler(`${config.apiUrl}/shippingMethod/set`, authPostDataRequest({ userPin, name, webOrderInstructions, invoiceInstructions, isActive, isDeliveryOption, isFreightOption, isPickupOption }))
    .then(result => { return result; });
}

function createOrder(userPin: any,
  customerId: any,
  firstName: string | null,
  lastName: string | null,
  emailAddress: string | null,
  phoneNumber: string | null,
  phoneNumberTypeId: number | null,
  paymentMethodId: any,
  shippingMethodId: any,
  addressId: any,
  streetAddress: string | null,
  suburb: string | null,
  city: string | null,
  postCode: string | null
): Promise<ApiResponse> {
  return fetchHandler(`${config.apiUrl}/weborders/create`, authPostDataRequest({ userPin, customerId, firstName, lastName, emailAddress, phoneNumber, phoneNumberTypeId, paymentMethodId, shippingMethodId, addressId, streetAddress, suburb, city, postCode }))
    .then(result => { return result; });
}

function addUserAddress(userPin: string, customerId: number, streetAddress: string, suburb: string, city: string, postCode: string, isDefault: boolean): Promise<Response> {
  return fetchHandler(`${config.apiUrl}/weborders/create`, authPostDataRequest({ userPin, customerId, streetAddress, suburb, city, postCode, isDefault }))
    .then(result => { return result; });
}
