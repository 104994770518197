import * as ko from 'knockout';
import { Observable } from 'knockout';
import { webOrdersService } from '../../../api/service.weborders';
import { formatDate, formatTime } from '../../../utils/format';
import app, { dialog } from '../../app';
import { DialogParams, FieldType } from '../../elements/bp-dialog';

class WebOrdersInvoiceDetails {
  readonly title: ko.PureComputed<string>;
  readonly webOrderId = ko.observable<number>();
  readonly webOrderInvoiceId: Observable<number>;

  readonly dialog: Observable<DialogParams | null>;

  readonly emailMessage = ko.observable<string>();
  readonly success = ko.observable<string>();
  readonly recipientEmailAddress = ko.observable<string>();
  readonly lastAttemptTime = ko.observable<string>();

  constructor(params: any) {
    app.checkBeforeNavigation(false);

    this.webOrderInvoiceId = ko.observable(params.id);

    this.title = ko.pureComputed(() => 'Latest Invoice for Order #' + this.webOrderId());

    this.dialog = ko.observable(null);

    webOrdersService.getInvoice(this.webOrderInvoiceId())
      .then(invoice => {
        this.webOrderId(invoice.webOrderId);
        this.emailMessage(invoice.fullMessageBody);
        this.success(invoice.succeeded ? 'Succeeded' : 'Failed');
        this.recipientEmailAddress(invoice.recipientEmailAddress);
        this.lastAttemptTime(formatDate(invoice.lastAttemptTime) + ' ' + formatTime(invoice.lastAttemptTime));
      });
  }

  goto = {
    resendInvoice: (): void => {
      const origMessage = '<span style="color: red;">You are about to send a copy of this invoice to the customer.  Are you sure you want to do this?</span>';
      const message = ko.observable(origMessage);

      const email = this.recipientEmailAddress();

      const model = {
        userPin: ko.observable<string>(''),
        sendEmail: ko.observable<string>(email!)
      };

      const userPinField: FieldType<string> = {
        title: 'User Pin',
        type: 'userPin',
        value: model.userPin,
        setFocus: true
      };

      const emailField: FieldType<string> = {
        title: 'Recipient Email Address',
        type: 'text',
        value: model.sendEmail
      }

      dialog({
        title: `Please confirm this action.`,
        message: message,
        fields: ko.observableArray([userPinField, emailField]),
        cancelText: 'Cancel',
        submitText: 'Confirm',
        submitAction: () => {
          let errors = '';

          //Check fields
          if (model.userPin() === '') {
            errors += '<br />User  PIN cannot be empty. <br />';
          }

          if (errors !== '') {
            message(`${origMessage}<br /> <div class="error"><ul>${errors}</ul></div>`);

            return;
          }

          webOrdersService.resendInvoice(this.webOrderId()!, this.webOrderInvoiceId(), model.userPin()!, model.sendEmail())
            .then(result => {
              if (result.success) {
                alert("Invoice has been queued to resend.  Check the email logs to ensure it's succeeded.");
                dialog(null);

              } else {
                message(`${origMessage} <br /><br><div class="error"> - ${result.message}.</div>`);
              }
            });
        }
      });
    },
  }
}

export default {
  name: 'bp-weborders-invoice-detailss',
  viewModel: WebOrdersInvoiceDetails,
  template: require('./invoice-details.html')
};
